import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';
import type { ITradingInfoRes } from './trading.type';

export const getTradingInfo = (params: unknown): Promise<APIResponse<ITradingInfoRes>> => {
  const URL = `${config.api.host_api_v1}${config.api.trade.tradingInfo}?${helper.mapToQueryString(params ?? {})}`
  return request.get.call({}, URL);
}

export const checkDeposit = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.trade.depositStatus}?${helper.mapToQueryString(params ?? {})}`
  return request.get.call({}, URL);
}

/**
   * 获取邀请码,活动规则链接，活动描述,返佣比例等基础信息
   * @param params
   * @returns {*}
   */
export const rebateInfo = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.customer.rebateInfo}?${helper.mapToQueryString(params ?? {})}`
  return request.get.call({}, URL);
};

export const changeMargin = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.trade.changeMargin}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
};

/**
   * 根据传参不同，获取当前委托/历史委托页面的接口数据
   * @param {String} params.saas_id String 必传 平台标识 kiki; request 统一封装了，业务无需处理
   * @param {String} params.symbol String 要查询的盘口，不传的话默认查所有盘口的 如：BTC_USDT
   * @param {String} params.order_type String 要查询的交易类型，取值:limit - 限价, market - 市价。不传的话默认都查
   * @param {String} params.side String 要查询买还是卖，取值：buy - 买, sell - 卖。不传的话默认都查
   * @param {String} params.from_order_id String 分页查询时上一批返回的最后一条记录的orderId。不传的话默认从最新的开始查
   * @param {String} params.limit Number 分页查询时返回的记录数量。不传默认10
   * @param {String} params.start Number 起始的时间戳，13位,和end合起来使用，缺任何一个都不起作用
   * @param {String} params.end Number 截止到时间戳，13位,和start合起来使用，缺任何一个都不起作用
   * @param {String} params.query_status String 必传 要查询的order的状态：open - 查询交易中的； history - 查询历史的； all - 查询所有的
   * @param {String} params.type String 业务类型，取值：spot - 现货，margin - 杠杆
   * @param {String} params.business_type String 订单类型，取值：trading - 交易订单，margin_settle - 清算订单，不传的话默认查全部
   * @param {String} params.hide_cancel String 是否隐藏已撤销订单，true-隐藏，false-不隐藏，不传的话默认false
   * @returns {*}
   */
export const getNowEntrust = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.trade.order}?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};

/**
   * 获取历史成交页面的接口数据
   * @param params
   * @returns {*}
   */
export const getHistoryTrade = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.trade.tradingTrades}?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};

/**
   * 根据一级历史委托页面，获取orderid传参，获取二级历史委托页面的接口数据
   * @param params
   * @returns {*}
   */
export const getTradeOrderDetail = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.trade.orderDetailFull.replace(/{orderId}/, params.order_id) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
/**
   * 根据当前委托页面，获取orderid，进行撤单功能
   * @param params
   * @returns {*}
   */
export const cancelTradeOrder = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.trade.cancelOrder.replace(/{orderId}/, params.order_id) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.delete.call({}, URL);
};
