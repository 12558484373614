import React from 'react';

import { type ButtonProps, Button as AntdButton, ConfigProvider } from 'antd';
import cn from 'classnames';

export interface IButtonProps extends ButtonProps {
  content?: React.ReactNode;
  borderRadius?: number | string;
}

const getBorderRadius = (borderRadius) => {
  if (typeof borderRadius === 'number') {
    return `${borderRadius}px`;
  }
  return borderRadius;
};

export const Button: React.FC<IButtonProps> = (props) => {
  const {
    children,
    content,
    loading = false,
    className,
    borderRadius = 4,
    ...otherProps
  } = props;
  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <AntdButton
        style={{ borderRadius: getBorderRadius(borderRadius) }}
        {...otherProps}
        className={cn('kiki-button', className)}
        loading={loading}
      >
        {loading ? '' : children || content}
      </AntdButton>
    </ConfigProvider>
  );
};
