import { MarketAPI } from './market'
import dayjs from 'dayjs';
const MAX_AGE = 30 * 60;
const createFetchSymbolsFn = () => {
  const cache = { unix: 0 };
  return async () => {
    const currUnix = dayjs().unix();
    if (cache.data && currUnix - cache.unix <= MAX_AGE) {
      console.log('line 9 缓存')
      return cache.data
    }
    try {
      console.log('line 12 请求')
      const res = await MarketAPI.marketSymbols()
      cache.data = res;
      cache.unix = currUnix;
      return cache.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const fetchSymbols = createFetchSymbolsFn();
