
import React, { useState, useRef } from 'react';
import cn from 'classnames'
import styles from './index.module.css'
import Icon from '@components/Icon'
import useClient from '@lib/hooks/useClient';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'

export const Swiper = (props) => {
  const { dataSource = [], pageSize = 3, renderItem, keyExtractor, ItemSeparatorMargin, containerClassName, listClass, itemClass, ...rest } = props;
  const isPc = useClient()

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const [currentData, setCurrentData] = useState([]);
  const currentRef = useRef(1);

  const length = dataSource.length;
  const maxPage = Math.ceil(length / pageSize)

  useIsomorphicLayoutEffect(() => {
    setCurrentData(isPc ? dataSource.slice(0, pageSize) : dataSource)
    if (maxPage > 1) {
      setShowRight(true)
    }
  }, [dataSource, isPc])

  useIsomorphicLayoutEffect(() => {
    const showR = maxPage > 1 && currentRef.current < maxPage
    const showL = currentRef.current !== 1
    setShowRight(showR)
    setShowLeft(showL)
  }, [currentRef.current])

  useIsomorphicLayoutEffect(() => {
    currentRef.current = 1;
  }, [isPc])

  const nextPage = () => {
    if ((currentRef.current + 1) < maxPage) {
      setCurrentData(dataSource.slice(currentRef.current * pageSize, (currentRef.current + 1) * pageSize))
      currentRef.current = currentRef.current + 1;
    } else if ((currentRef.current + 1) === maxPage) {
      if (length % pageSize === 0) {
        setCurrentData(dataSource.slice(currentRef.current * pageSize, (currentRef.current + 1) * pageSize))
      } else {
        setCurrentData(dataSource.slice(currentRef.current * pageSize - (pageSize - length % pageSize), dataSource.length))
      }
      currentRef.current = currentRef.current + 1;
    }
  }

  const prePage = () => {
    if (currentRef.current > 1) {
      setCurrentData(dataSource.slice((currentRef.current - 2) * pageSize, (currentRef.current - 1) * pageSize))
      currentRef.current = currentRef.current - 1;
    }
  }
  return (
    <div {...rest} className={cn(styles.container, containerClassName)}>
      <div className={cn(styles.list, listClass)}>
        {
          (isPc && showLeft) && (
            <div className={styles.leftContainer}>
              <div className={styles.left} onClick={prePage} data-buried="pre">
                  <Icon name='arrow2'/>
              </div>
            </div>
          )
          }
            {
              currentData.map((item, index) => {
                const MarginRight = index === currentData.length - 1 ? '0' : ItemSeparatorMargin
                return (
                <div
                  key={keyExtractor || index}
                  style={{ marginRight: `${MarginRight}` }}
                  className={cn(styles.listItem, itemClass)}
                >
                  {renderItem(item, index)}
                </div>)
              })
            }
            {
              (isPc && showRight) && (
              <div className={styles.rightContainer}>
                <div className={styles.right} onClick={nextPage} data-buried="next">
                    <Icon name='arrow2'/>
                </div>
              </div>
              )
            }
      </div>
    </div>
  )
}
