export const FEMALE = 'female';
export const MALE = 'male';
export const ENABLE = 'ENABLE';
export const DISABLE = 'DISABLE';
export const PRECISION = 2;
export const PRECISION8 = 8;
export const ZERO = 0;
export const HKD = 'HKD';
export const USD = 'USD';
export const FIAT_CURRENCY = [USD, HKD];

export const PASSWORD_LEVEL_HIGHT = 'hight';
export const PASSWORD_LEVEL_MID = 'mid';
export const PASSWORD_LEVEL_LOW = 'low';

export const ZHTC = 'zh-TC';
export const EN = 'en';
export const ZHCN = 'zh-CN';

export const MOBILE = 'mobile';
export const EMAIL = 'email';

export const THEME: {
  [key in 'DARK' | 'HIGHLIGHT']: Theme
} = {
  /** 深色模式 */
  DARK: 'dark',
  /** 浅色模式 */
  HIGHLIGHT: 'highlight'
}
