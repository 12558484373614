import { EN, ZHCN, ZHTC } from '@constants/index'

import config from './config'
import { en, hk, zh } from './language'

export const locales = [EN, ZHCN, 'zh-HK', 'zh-TW', ZHTC];

export const languageOptions = [
  { label: '中文（繁體）', value: ZHTC, key: 'hk' },
  { label: 'English', value: EN, key: 'en' },
  { label: '中文（简体）', value: ZHCN, key: 'zh' }
]
const I18nTranslations = { en, zh, hk };
const mergeRemoteLocale = async function (locale) {
  const url3 = config.api.kiki_website_url + 'language/zoneDesc-' + locale.toLowerCase() + '.json?a=' + Date.now()
  const url4 = config.api.kiki_website_url + 'language/api-' + locale.toLowerCase() + '.json?a=' + Date.now()

  try {
    const responses = await Promise.all([fetchRemoteLocale(url3), fetchRemoteLocale(url4)]);
    // eslint-disable-next-line  no-eval
    switch (locale) {
      case 'en':
        I18nTranslations.en = Object.assign({}, I18nTranslations.en, responses[0], responses[1])
        break;
      case 'hk':
        I18nTranslations.hk = Object.assign({}, I18nTranslations.hk, responses[0], responses[1])
        break;
      case 'zh':
        I18nTranslations.zh = Object.assign({}, I18nTranslations.zh, responses[0], responses[1])
        break;
    }
  } catch (error) {
    console.log('error:::', error)
  }
}
export const fetchRemoteLocale = function (url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        return response.json();
      }).then(function (data) {
        resolve(data);
      }).catch((err) => {
        console.log('error:::', err)
      });
  })
}

const handleMsg = (msg, options) => {
  if (!msg) {
    return msg
  }
  const reg = /(%\{([^}]+)\})/g;
  const groups = Array.from(msg.matchAll(reg));
  if (!groups.length) {
    return msg
  }
  let newMessage = msg;
  groups.forEach(([group, , key]) => {
    newMessage = newMessage.replace(group, options[key.trim()])
  })
  return newMessage
}

const I18nUtil = {
  t: function (msg, options) {
    switch (I18nUtil.locale) {
      case ZHCN:
        return handleMsg(I18nTranslations.zh?.[msg] ?? msg, options)
      case ZHTC:
        return handleMsg(I18nTranslations.hk?.[msg] ?? msg, options)
      case EN:
      default:
        return handleMsg(I18nTranslations.en?.[msg] ?? msg, options)
    }
  },
  locale: EN,
  updateLocale: (newLocale) => {
    I18nUtil.locale = newLocale
  },
  mergeRemoteLocale
};

export const mergeI18nTranslations = ({ en, zh, hk }) => {
  I18nTranslations.en = Object.assign({}, I18nTranslations.en, en)

  I18nTranslations.zh = Object.assign({}, I18nTranslations.zh, zh)

  I18nTranslations.hk = Object.assign({}, I18nTranslations.hk, hk)
}

export default I18nUtil;
