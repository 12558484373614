
const api = {
  // host: 'https://newapi.beta.dipbit.xyz/',
  // host_newapi: 'https://api.beta.dipbit.xyz/',
  // host_api_v1: 'https://api.beta.dipbit.xyz/',
  host: 'https://webbeta.kikitrade.com/api/',
  host_newapi: 'https://webbeta.kikitrade.com/newapi/',
  host_api_v1: 'https://webbeta.kikitrade.com/newapi/',
  upload_api: 'https://upload.dipbit.xyz/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738',
  share: 'https://share.dipbit.xyz/',
  kiki_website_url: 'https://webbeta.kikitrade.com/website/',
  get_short_link_url: 'https://s.dipbit.xyz'
};

export default api;
