import axios from 'axios';
import I18n from './i18n';
import { message } from 'antd';
import { getLoginPath } from '@utils/index'
import jwtToken from '../utils/jwtToken';
import { getDeviceId } from '@utils/getDeviceId';
import { logger } from './logtail';
import version from 'public/version.json';

const defaultOpt = {
  ignoreError: false,
  isSilent: false,
  needLogin: false
}

const defaultErrorResponse = {
  code: 'system.error',
  msg: 'system or client request error',
  msgKey: 'system.error',
  msgParam: [],
  obj: {},
  success: false
};
const request = {};
let promptedPath = ''

function setGlobalRequestHeader (params) {
  if (typeof window !== 'undefined') {
    Object.keys(params).forEach((key) => {
      window[key] = params[key]
    })
  }
}

// 仅在服务端上报日志
const handleLogger = (fetchOpt, response) => {
  if (typeof window !== 'undefined') return;

  const keys = Object.keys(response.data);
  if (keys.includes('success') && response.data?.success) return;
  if (!keys.includes('success') && keys.includes('result') && !!response.data?.result) return
  logger.error?.('server request failed', {
    request: fetchOpt,
    response: {
      status: response.status,
      data: response.data
    }
  })
}

axios.interceptors.response.use(function (response) {
  if (response.data?.code === '1000' && typeof window !== 'undefined') {
    if (location.pathname === promptedPath) {
      return Promise.reject(response.data.msgKey)
    }
    message.error(I18n.t(response.data.msgKey))
    promptedPath = location.pathname;
    localStorage?.removeItem('userStore')
    localStorage?.removeItem('tradeStore')
    location.href = getLoginPath();
    return Promise.reject(new Error(response.data.msgKey))
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

async function doRequest (method, url, opts = defaultOpt, data) {
  // console.log(opts, '----optsopts-----')
  url = await joinParamsPost(url);
  const startRequestTime = new Date().getTime()
  let userId = ''
  const deviceId = Object.prototype.hasOwnProperty.call(opts, 'deviceId') ? opts.deviceId : await getDeviceId()
  try {
    userId = JSON.parse(localStorage.getItem('userStore')).userInfo.id || ''
  } catch (e) {
  }

  const baseHeader = {
    JWT_TOKEN: opts.jwtKey ?? jwtToken.getToken() ?? '',
    Authorization: opts.jwtKey ?? jwtToken.getToken() ?? '',
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    saas_id: 'kiki',
    device_type: 'web',
    customer_id: userId,
    device_id: deviceId
  }

  const headers = opts.verificationType === 1
    ? {
        ...baseHeader,
        deviceId: '161B013A-FBF6-49B9-BE04-5671C9C96BB2',
        trace_id: '',
        'SG-Verification-Type': opts.verificationType,
        'SG-Verification-Sig': opts.sig,
        'SG-Verification-Token': opts.token,
        'SG-Verification-Scene': opts.scene,
        'SG-Verification-Session-Id': opts.sessionId
      }
    : (opts.Authorization
        ? {
            ...baseHeader,
            'SG-Verification-Type': opts.verificationType || '',
            Authorization: opts.Authorization,
            'SG-Verification-Session-Id': opts.sessionId || ''
          }
        : {
            ...baseHeader,
            MODE: opts.MODE ?? ''
          });
  if (process.env.NEXT_PUBLIC_API_ENV === 'pre' || process.env.NEXT_PUBLIC_API_ENV === 'pre1' || process.env.NEXT_PUBLIC_API_ENV === 'prodgreen' || process.env.NEXT_PUBLIC_API_ENV === 'beta1') {
    headers.traffic = 'green'
  }
  headers['Content-Type'] = opts['Content-Type'] ?? headers['Content-Type']
  headers.version = version.date;
  setGlobalRequestHeader({
    jwtKey: headers.JWT_TOKEN,
    USER_ID: userId,
    DEVICE_ID: deviceId,
    device_type: 'web',
    sass_id: 'kiki',
    // version: headers.version,
    traffic: headers.traffic
  })

  const fetchOpt = {
    url,
    timeout: 10 * 1000,
    method,
    data: null,
    withCredentials: true,
    headers
  }
  if (method === 'POST') {
    const _params = url.split('?')[1]
    if (_params) {
      fetchOpt.data = data || JSON.parse('{"' + _params.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      fetchOpt.data.saas_id = 'kiki'
    }
  }
  let responseData;
  try {
    // console.log('+++++response+++++2222', fetchOpt)
    const response = await axios(fetchOpt);
    // console.log(response, '+++++response+++++')
    const requestTime = new Date().getTime() - startRequestTime
    console.log(new Date().getTime() + '  url: ' + url + ', requestTime ' + requestTime)

    handleLogger(fetchOpt, response);

    if (response.status !== 200) {
      return defaultErrorResponse;
    }
    responseData = response.data;
    if (responseData.msgKey === 'system.error') {
      throw new Error('system.error')
    }

    if (responseData.code === '500' || responseData.code === '405') {
      throw new Error('system.error')
    }
    return responseData;
  } catch (error) {
    const response = error.response || {};
    handleLogger(fetchOpt, response)
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      // bugsnag.notify(new Error(
      //   'time:' + helper.toLocaleTime(new Date().getTime()) +
      //     ', url :' + url +
      //     ', method : ' + method +
      //     ', params : ' + JSON.stringify(opts) +
      //     ', axios___error__response__status: ' + error.response.status +
      //     ', axios___error__response__data: ' + error.response.data +
      //     ', axios___error__response__headers: ' + JSON.stringify(error.response.headers) +
      //     ', axios___error__response__message : ' + error.message
      // ));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);

      // bugsnag.notify(new Error(
      //   'time:' + helper.toLocaleTime(new Date().getTime()) +
      //     ', url :' + url +
      //     ', method : ' + method +
      //     ', params : ' + JSON.stringify(opts) +
      //     ', axios___error__message: The request was made but no response was received'
      // ));
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);

      // bugsnag.notify(new Error(
      //   'time:' + helper.toLocaleTime(new Date().getTime()) +
      //     ', url :' + url +
      //     ', method : ' + method +
      //     ', params : ' + JSON.stringify(opts) +
      //     ', axios___error__message : ' + error.message
      // ));
    }
    // console.log(error, '----------error---------')
    throw new Error('system.error')
  }
}

request.get = async function (url, opts = defaultOpt) {
  return doRequest('GET', url, opts);
}

request.post = async function (url, opts = defaultOpt, data) {
  return doRequest('POST', url, { ...defaultOpt, ...opts }, data);
}

request.delete = async function (url, opts = defaultOpt) {
  return doRequest('DELETE', url, opts);
}
request.put = async function (url, opts = defaultOpt) {
  return doRequest('PUT', url, opts);
}

const joinParamsPost = async function (params) {
  let language = ''
  const locale = I18n.locale;
  // if (locale === 'zh') {
  //   locale = 'zh_CN';
  // }

  language = locale;
  language = language.replace(/,/g, '')
  if (params.indexOf('language') === -1) {
    if (params.indexOf('?') >= 0) {
      params += '&language=' + language;
    } else {
      params += '?language=' + language
    }
  }

  // console.log("url",params);
  return params;
}

export default request;
