import { message } from 'antd';
import cookie from 'js-cookie';
import { action, makeObservable, observable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store';
import { enableStaticRendering } from 'mobx-react'

import I18n from '@utils/i18n';

import { CustomerAPI } from '../api/customer';
import { getUserInfo } from '../api/feedStream'
import helper from '../utils/helper';
import jwtToken from '../utils/jwtToken';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined')

let store

class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
    makeObservable(this)
    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'userStore',
        properties: ['userInfo', 'isLogin', 'webToken', 'socialUserInfo', 'showAssetAmount'],
        storage: window.localStorage
      }).then(
        action((persistStore) => {
          if (persistStore.isHydrated) {
            jwtToken.setToken(persistStore.target.userInfo.jwtKey || '')
          }
        })
      )
        .catch(error => {
          console.log(error)
        })
    }
  }

  @observable userInfo = {}
  @observable socialUserInfo = {}
  @observable isLogin = false
  @observable showAssetAmount = true

  webToken = helper.createUUID()

  @action doLogin = async (params, options, callback, errorCallback) => {
    try {
      const response = await CustomerAPI.login(params, options);
      if (response.success && response.obj) {
        this.loginSuccess(response.obj)
        callback(response)
      } else if (response.code === '0119') {
        callback(response)
      } else {
        errorCallback && errorCallback(response)
      }
    } catch (e) {
      errorCallback && errorCallback(e)
    }
  }

  @action loginSuccess = (userInfo) => {
    runInAction(() => {
      this.isLogin = true
      this.userInfo = userInfo
      cookie?.set?.('jwt', this.userInfo.jwtKey);
      cookie?.set?.('userId', this.userInfo.id);
      jwtToken.setToken(this.userInfo.jwtKey)
    })
  }

  @action getUserSocialInfo = async (params, callback, errorCallback) => {
    try {
      const response = await getUserInfo(params.userId, params.token);
      if (response && response.customerId) {
        runInAction(() => {
          console.log(response)
          this.socialUserInfo = response
          cookie?.set?.('zone', this.socialUserInfo.zone);
          callback && callback(response)
        })
      } else {
        errorCallback && errorCallback(response)
      }
    } catch (e) {
      errorCallback && errorCallback(e)
    }
  }

  /**
   type ParamsType = {
    pTokenType: string,
    idToken: string,
    email: ?string,
    token: ?string,
    password: ?string,
    deviceId: string
  };
   */
  @action doLoginOauth = async (params, callback, errorCallback) => {
    try {
      const response = await CustomerAPI.loginOauth(Object.assign(params, { pTokenType: 'web' }));

      if (response.success && response.obj) {
        this.loginSuccess(response.obj)
        callback(response)
      } else if (response.code === '0119') {
        callback(response)
      } else {
        errorCallback && errorCallback(response)
      }

      // if (response.success && response.obj) {
      //   runInAction(() => {
      //     this.isLogin = true
      //     this.userInfo = response.obj
      //     jwtToken.setToken(this.userInfo.jwtKey)
      //     callback && callback(response)
      //   })
      // } else {
      //   errorCallback && errorCallback(response)
      // }
    } catch (e) {
      errorCallback && errorCallback(e)
    }
  }

  @action updateShowAssetAmount = (showAssetAmount) => {
    runInAction(() => {
      this.showAssetAmount = showAssetAmount
    })
  }

  @action updateUserInfor = async (params, callback, errorCallback) => {
    runInAction(() => {
      this.isLogin = true
      this.userInfo = {
        ...this.userInfo,
        ...params
      }
    })
  }

  @action updateSocialUserInfo = async (params) => {
    runInAction(() => {
      this.socialUserInfo = {
        ...this.socialUserInfo,
        ...params
      }
      cookie?.set?.('zone', this.socialUserInfo.zone);
    })
  }

  @action security = async () => {
    try {
      const res = await CustomerAPI.security({ scene: 0 });
      if (!res.success) {
        message.error(I18n.t(res.msgKey))
      }
      return res.success
    } catch (error) {
    }
  }

  @action logout = async () => {
    try {
      const res = await CustomerAPI.logout();
      if (!res.success) {
        return Promise.resolve(res.msgKey)
      }
      runInAction(() => {
        this.isLogin = false
        this.userInfo = {}
        jwtToken.setToken('')
        cookie?.remove?.('jwt');
        cookie?.remove?.('userId');
        cookie?.remove?.('zone');
        this.socialUserInfo = {}
      })
      return Promise.resolve(true)
    } catch (error) {
      console.log(error)
    }
  }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore)

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function getUserStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState)
  return store
}
