import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';

// class definition
class SpotTradeApi {
  getZoneList (params = {}) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.zoneList +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getZoneDetail (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.zoneDetail.replace(/{key}/, params.key).replace(/{currency}/, params.currency) +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  getCurrencyDetail (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.currencyDetail.replace(/{currency}/, params.currency)
    return request.get.call({}, URL);
  };

  getOrderbooks (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.orderbooks.replace(/{symbol}/, params.symbol)
    return request.get.call({}, URL);
  };

  marginInterests (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginInterests +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  marginSettleOrders (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginSettleOrders +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  /** 用户交易的设置 */
  marginBalance (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.marginBalance +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  }

  getBaseCurrencies () {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.baseCurrencies
    return request.get.call({}, URL);
  };

  createTradeOrder (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.order +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  tradeAcquireOrderId () {
    const URL =
      config.api.host_api_v1 +
      config.api.trade.acquireOrderId
    return request.post.call({}, URL);
  };
}

const SpotTradeAPI = new SpotTradeApi();
export { SpotTradeAPI };
