import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { enableStaticRendering } from 'mobx-react';

import { SpotTradeAPI } from '@api/spotTrade';

import { MarketAPI } from '../api/market';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');

let store;

const extractFiatCoinDetailFromMarket = (rateType, fiatMarketData) => {
  let otherRateSymbol = 'USDT_HKD';
  let coinDetail;
  let otherRateDetail;
  if (rateType) {
    const quoteCurrency = rateType.split('_')[1];
    if (quoteCurrency) {
      if (quoteCurrency === 'HKD') {
        otherRateSymbol = 'USDT_USD';
      }
      fiatMarketData.forEach((item) => {
        if (item.coinCode === rateType) {
          coinDetail = item;
        }
        if (item.coinCode === otherRateSymbol) {
          otherRateDetail = item;
        }
      });
      return { coinDetail, otherRateDetail };
    }
  }
  return { coinDetail, otherRateDetail };
};
class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable marketSymbols = [];
  @observable marketCoins = [];
  @observable marketCoinsObj = {};
  @observable baseCurrenciesList = [];
  @observable coinDetail = [];
  @observable otherRateDetail = [];
  @observable hotSearchList = [];
  // @observable coinDetail = []
  // @observable otherRateDetail = []
  @observable recommendList = [];

  @action fetchRecommendList = async () => {
    try {
      const response = await MarketAPI.fetchRecommend();
      if (response.success && response.obj) {
        runInAction(() => {
          this.recommendList = response.obj;
        });
        return Promise.resolve(response.obj);
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  @action fetchMarketSymbol = async () => {
    try {
      const response = await MarketAPI.marketSymbols();
      if (response.success && response.obj) {
        runInAction(() => {
          const marketSymbols = response.obj.map(i => ({ ...i, precision: i.symbolPrecision }))
          this.marketSymbols = marketSymbols;
        });
        return Promise.resolve(response.obj);
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  @action fetchMarketCoin = async () => {
    try {
      const response = await MarketAPI.coinList();
      if (response.success && response.obj) {
        runInAction(() => {
          this.marketCoins = response.obj;
          this.marketCoinsObj = response.obj.reduce((prev, curr) => {
            prev[curr.coinCode] = curr;
            return prev
          }, {});
        });
        return response.obj;
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  @action fetchBaseCurrencies = async () => {
    try {
      const response = await SpotTradeAPI.getBaseCurrencies();
      if (response.success && response.obj) {
        runInAction(() => {
          this.baseCurrenciesList = response.obj;
        });
        return response.obj;
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  @action updateMarketSymbol = (newMarketSymbols) => {
    this.marketSymbols = newMarketSymbols;
  };

  @action fullMarketSymbols = async () => {
    const { showAssetAmount } = this.rootStore.userStore;
    console.log(showAssetAmount, 'showAssetAmount');
    const marketSymbols = await this.fetchMarketSymbol();
    const rateType = localStorage.getItem('preferencesExchangeRate');
    const _rateType = 'USDT_' + (rateType || 'USD');
    console.log(marketSymbols, 'marketSymbols');
    const { coinDetail, otherRateDetail } = extractFiatCoinDetailFromMarket(
      _rateType,
      marketSymbols
    );
    runInAction(() => {
      this.coinDetail = coinDetail;
      this.otherRateDetail = otherRateDetail;
    });
  };

  @action hotSearch = async () => {
    const response = await MarketAPI.hotSearch();
    if (response && response.obj) {
      runInAction(() => {
        this.hotSearchList = response.obj;
      });
    }
  };

  @computed get timeString () {
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const format = (t) =>
      `${pad(t.getUTCHours())}:${pad(t.getUTCMinutes())}:${pad(
        t.getUTCSeconds()
      )}`;
    return format(new Date(this.lastUpdate));
  }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore);

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function getMarketStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState);
  return store;
}
