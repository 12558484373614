import React from 'react';
import { message } from 'antd';
import { copyText } from '@utils/clipboard'
import Icon from '@components/Icon';
import cn from 'classnames';
import styles from './index.module.css';
import I18n from '@utils/i18n';

// export interface ICopyBtnProps {
//   locale: String;
//   copyText: String;
//   className?: String;
//   IconName?: String;
// }

export function CopyBtn (props) {
  const { className, text, IconName = 'tableCopy', ...rest } = props;

  return (
    <span
      {...rest}
      className={cn(styles.copyIcon, className)}
      onClick={() => {
        copyText(text)
        message.success(I18n.t('copySuccess'), 2)
      }}
    >
      <Icon name={IconName} />
    </span>

  )
}
