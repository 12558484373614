import { action, observable, runInAction, computed } from 'mobx'
import { enableStaticRendering } from 'mobx-react'
// import { makePersistable } from 'mobx-persist-store';

import * as AccountAPI from '@api/index';
// import { getUserInfo } from '../api/feedStream'
// import helper from '../utils/helper';
// import jwtToken from '../utils/jwtToken';
import { message } from 'antd';
import I18n from '@utils/i18n';
import _, { mapValues } from 'lodash';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined')

let store

class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  @observable assetCoinList = {}
  @observable assetCoinListfilter = []
  @observable withdrawConfig = {}
  @observable chainType = ''
  @observable memoLabel = ''
  @observable withdrawAddrListLoading = false
  @observable withdrawAddrList = []

  @computed get withdrawAddrListData () {
    return this.withdrawAddrList
  }

  @action fetchAssetCoinList = async (params, callback, errorCallback) => {
    try {
      const response = await AccountAPI.withdrawAllConfig();
      if (response.success && response.obj) {
        let obj = response.obj; const _obj = {}
        if (Object.prototype.toString.call(response.obj) === '[object Array]') {
          /* $FlowFixMe */
          response.obj.forEach(
            (item) => {
              _obj[item.currency] = item
            })
          obj = _obj
        }
        let assetCoinListfilter
        runInAction(() => {
          this.assetCoinList = obj
          assetCoinListfilter = mapValues(obj) ?? []
          assetCoinListfilter = _.filter(
            assetCoinListfilter,
            (item) => {
              return item.enableWithdraw === true && item.fiat === false;
            }
          );
          this.assetCoinListfilter = assetCoinListfilter
          // this.selectData = assetCoinListfilter.ma
        })
        return Promise.resolve(assetCoinListfilter)
      } else {
        message.error(I18n.t(response.code))
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action payCurrenciesInfo = async (coinCode) => {
    try {
      const response = await AccountAPI.getPayCurrenciesInfo2({ coinCode });
      const retObj = response.obj;
      runInAction(() => {
        this.withdrawConfig = retObj
        this.chainType = retObj?.assets[0].asset
        this.memoLabel = retObj?.assets[0].memoLabel
      })
      console.log(retObj, 'retObj')
      if (retObj?.assets[0].asset) {
        await this.fetchWithdrawAddressList(retObj?.assets[0]?.asset);
      }
      return {
        withdrawConfig: retObj,
        chainType: retObj?.assets[0].asset,
        memoLabel: retObj?.assets[0].memoLabel
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action fetchWithdrawAddressList = async (coinCode) => {
    try {
      const response = await AccountAPI.withdrawAddressList(coinCode);
      if (response.success && response.obj) {
        runInAction(() => {
          this.withdrawAddrList = response.obj && response.obj.rows ? response.obj.rows : response.obj
        })
      } else {
        message.error(I18n.t(response.msgKey))
      }
    } catch (e) {
      // message.error(I18n.t('system.error', local))
    }
  }

  @action applyWithdraw = async (params) => {
    const { security } = this.rootStore.userStore
    console.log(security, 'security', this.rootStore.userStore)
    const res = await security()
    if (!res) {
      return
    }
    console.log(res, 'resresresres')
    try {
      const response = await AccountAPI.applyWithdraw(params);
      if (response.success && response.obj) {
        return true
      } else {
        message.error(I18n.t(response.msgKey))
      }
    } catch (e) {
      message.error(I18n.t('system.error'))
    }
  }

  @action delWithdrawAddress = async (params) => {
    try {
      const response = await AccountAPI.delWithdrawAddress(params);
      if (response.success && response) {
        // return true
        await this.fetchWithdrawAddressList(params.currency);
        return true
      } else {
        message.error(I18n.t(response.msgKey))
      }
    } catch (e) {
      message.error(I18n.t('system.error'))
    }
  }

  @action createWithdrawAddress = async (params) => {
    try {
      const response = await AccountAPI.createWithdrawAddress(params);
      if (response.success && response) {
        // return true
        await this.fetchWithdrawAddressList(params.currency)
        message.success(I18n.t('2XI3GD6M'))
        return true
      } else {
        message.error(I18n.t(response.msgKey))
      }
    } catch (e) {
      message.error(I18n.t('system.error'))
    }
  }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore)

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function getWithdrawalStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState)
  return store
}
