import bigDecimal from 'js-big-decimal';
const helper = require('@utils/helper');

// 带千分号的金额格式化
export const formatPrettyValue = (numberStr: string) => {
  const formatted = /^[-+]?[0-9]+\.{0,1}[0-9]{0,}$/;
  if (formatted.test(numberStr)) {
    return bigDecimal.getPrettyValue(numberStr, 3, ',');
  }
  return numberStr;
}

// 业务逻辑封装 —— 千分号格式化 & 保留小数
export const formatPrice = (numOrString, digital) => {
  return formatPrettyValue(helper.formatFixNumString(numOrString, digital))
}

// 将千分位数字格式化为常规数字
export const dePrice = (numstr: string) => {
  return numstr.replace(',', '')
}
