import config from '@utils/config';
import { helper } from '@utils/index';
import request from '@utils/request';

import type {
  AirdropClaimHistory,
  AirdropClaimRecordType,
  AirdropDetailType,
  AirdropHistoryParams,
  AirdropRule,
  AirdropSendRecordType,
  AirdropSimpleType,
  JoinActivityParams,
  ReceiveAirdropReq,
  SendAirdropReq
} from './airdrop.type';
export type { AirdropRule } from './airdrop.type';

export const fetchAirdropRule = (): Promise<APIResponse<AirdropRule>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.airdropRule}`;
  return request.get.call({}, URL);
};

// 最近领取空投
export const fetchAirdropClaimHistory = (): Promise<APIResponse<AirdropClaimHistory>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.claimHistory}`;
  return request.get.call({}, URL);
};

// 开启空投校验
export const openAirdropCheck = (params: { id: string }): Promise<APIResponse> => {
  const URL = config.api.host_newapi + config.api.airdrop.openAirdropCheck +
    '?' +
    helper.mapToQueryString(params);
  return request.get.call({}, URL);
};
// 开启空投
export const openAirdrop = (params: { id: string }): Promise<APIResponse> => {
  const URL = config.api.host_newapi + config.api.airdrop.openAirdrop +
    '?' +
    helper.mapToQueryString(params);
  return request.post.call({}, URL);
};
// 查询空投简单详情（没有领取记录）
export const fetchAirdropSimpleDetail = (params: { id: string }): Promise<APIResponse<AirdropSimpleType>> => {
  const URL =
  `${config.api.host_newapi}${config.api.airdrop.simpleDetail.replace('{id}', params.id)}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
};

// 查询空投发送记录
export const fetchAirdropSendRecord = (params: AirdropHistoryParams): Promise<APIResponse<AirdropSendRecordType>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.sendRecord}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
};

// 查询领取的空投详情
export const fetchClaimAirdropDetail = (params: { id: string, receiveId: string }): Promise<APIResponse<AirdropDetailType>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.claimDetail.replace('{id}', params.id)
    .replace('{receiveId}', params.receiveId)}`;
  return request.get.call({}, URL);
}
// 查询空投领取记录
export const fetchAirdropClaimRecord = (params: AirdropHistoryParams): Promise<APIResponse<AirdropClaimRecordType>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.claimRecord}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
};

// 查询发送的空投详情
export const fetchSendAirdropDetail = (params: {id: string, option?: {deviceId?: string}})
: Promise<APIResponse<AirdropDetailType>> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.sendDetail.replace('{id}', params.id)}`;
  return request.get.call({}, URL, params.option);
};
export const fetchOpenAirdrop = (params: { id: string }): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.openAirdrop}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
};

export const shortLink = (params: { origin: string; rk?: Array<string> }): Promise<string> => {
  if (!params) {
    params = {
      origin: ''
    };
  }
  const URL = `${config.api.get_short_link_url}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
}
/** 发送空投 */
export const sendAirdrop = (params: SendAirdropReq): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.airdropSend}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL, {}, params);
}

/** 领取空投 */
export const receiveAirdrop = (params: ReceiveAirdropReq): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.airdrop.receiveAirdrop}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
}

/** 加入活动 */
export const joinActivity = (params: JoinActivityParams): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.activity.joinActivity}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}
