import type { IAccountAssetsRes, IAccountInfoRes, IAccountReq } from '@type/account';

import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';

/**
 * 查询账户总额度汇总
 * @param params
 * @returns {*}
 */
export const getAccountInfo = (params: IAccountReq): Promise<APIResponse<IAccountInfoRes>> => {
  const URL = `${config.api.host_api_v1}${config.api.account.info}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL)
}
/**
 * 获取今日收益数据
 * @param params
 * @returns {*}
 */
export const getTodaysPNL = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.getTodaysPNL}`;
  return request.get.call({}, URL)
}
/**
 * 查询账户币账户详情
 * @param params
 * @returns {*}
 */
export const getAssetsDetail = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.assetsDetail.replace(/{coinCode}/, params.coinCode)}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL)
}

/**
 * 获取单个出入金币种信息
 * @param params
 * @returns {*}
 */
export const getPayCurrenciesInfo = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.payCurrenciesInfo.replace(/{currency}/, params.coinCode)}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL)
}

/**
 * 获取单个出入金币种信息
 * @param params
 * @returns {*}
 */
export const getPayCurrenciesInfo2 = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.payCurrenciesInfo.replace(/{currency}/, params.coinCode)}`;
  return request.get.call({}, URL)
}

/**
 * 获取币种流水信息
 * @param params
 * @returns {*}
 */
export const getCoinHistory = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.history}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL)
}

/**
 * 获取币种充提信息
 * @param params
 * @returns {*}
 */
export const getAssetOrderList = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.assetOrderList}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL)
}

/**
 * 获取账户资产
 * @param params
 * @returns {*}
 */
export const accountAssets = (params: IAccountReq): Promise<APIResponse<IAccountAssetsRes>> => {
  if (!params) {
    params = {};
  }
  const URL = `${config.api.host_newapi}${config.api.account.assets}?${helper.mapToQueryString(params || {})}`;
  return request.post.call(params, URL);
};

// export const withdrawAllConfig = () => {
//   console.log('ccccccc')
//   const URL = config.api.host_api_v1 + config.api.account.withdrawAllConfig;
//   return request.get.call({}, URL)
// }

export const withdrawAddressList = (coinCode) => {
  // console.log(coinCode, 'coinCoderrr')
  const URL = config.api.host_api_v1 + config.api.accountV1.withdrawAddress.replace(/{asset}/, coinCode);
  return request.get.call({}, URL)
}

export const applyWithdraw = (params) => {
  let URL = ''
  URL =
    config.api.host_api_v1 +
    config.api.accountV1.withdrawApply.replace(/{asset}/, params.coinCode) +
    '?address=' +
    params.address +
    '&memo=' +
    (params.memo || '') +
    '&amount=' +
    params.amount +
    '&tokenId=' +
    params.tokenId;
  return request.post.call({}, URL);
}

export const delWithdrawAddress = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.accountV1.delWithdrawAddress
      .replace(/{asset}/, params.currency)
      .replace(/{addressId}/, params.id)
  return request.delete.call(params, URL);
};

export const createWithdrawAddress = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.accountV1.withdrawAddress.replace(/{asset}/, params.currency) + '?' + helper.mapToQueryString(params);
  return request.post.call({}, URL)
}
/**
 * 获取资产详情
 * @param params
 * @returns {*}
 */
export const assetsDetail = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.account.assetsDetail.replace(/{coinCode}/, params.coinCode)}`
  return request.get.call({}, URL)
}
/**
 * 获取全部出入金币种信息
 * @param params
 * @returns {*}
 */
export const withdrawAllConfig = () => {
  const URL = `${config.api.host_api_v1}${config.api.account.withdrawAllConfig}`;
  return request.get.call({}, URL)
}

/**
 * 获取充提币历史
 * @param params
 * @returns {*}
 */
export const assetOrderList = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.accountV1.assetOrderList + '?' + helper.mapToQueryString(params);
  return request.get.call({}, URL);
}

/**
 * 获取单个出入金币种信息
 * @param params
 * @returns {*}
 */
export const payCurrenciesInfo = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.accountV1.payCurrenciesInfo.replace(/{currency}/, params.coinCode)}`
  return request.get.call({}, URL)
}

export const showDepositAddress = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.accountV1.showDepositAddress.replace(/{asset}/, params.currency)}`;
  return request.get.call({}, URL);
}

export const depositAddress = (params) => {
  const URL = `${config.api.host_api_v1}${config.api.accountV1.depositAddress.replace(/{asset}/, params.currency)}`;
  return request.post.call({}, URL);
}

export const withdrawConfig = (params) => {
  const URL =
      config.api.host_api_v1 +
      config.api.account.withdrawConfig.replace(/{coinCode}/, params.coinCode)

  return request.get.call({}, URL)
}
